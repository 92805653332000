import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import RegisterStyles from './register_styles';
import { Field, TextField, InputLabel } from '../../../common/form';
import Button from '../../../common/components/button';
import Spinner from '../../../common/components/spinner';
import { useApiCall } from '../../../util/hooks';
import { OAUTH } from '../../../api';

const propTypes = {
  signedIn: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  location: PropTypes.shape({ state: PropTypes.shape({ returnTo: PropTypes.string }) }).isRequired,
};

const defaultProps = {
  pristine: false,
  invalid: false,
};

const oauthButton = (provider, returnTo) => (
  <a key={provider} href={OAUTH.getUrl(provider, returnTo)} className="oauth-button">
    {provider === 'developer' ? <i className="fas fa-user-secret fa-2x" /> : <i className={`fab fa-${provider} fa-5x`} />}
  </a>
);

const DEFAULT_RETURN_TO = '/dashboard';

const RegisterView = ({ signedIn, handleSubmit, pristine, invalid, location: { state } }) => {
  const [onSubmit, { submitting, failed }] = useApiCall(handleSubmit);
  const returnTo = (state && state.returnTo) || DEFAULT_RETURN_TO;

  if (signedIn) {
    return <Redirect to={returnTo} />;
  }

  const oauthRow = (
    <div className="oauth-row">
      <span>Sign in with:</span>
      {OAUTH.providers.map((provider) => oauthButton(provider, returnTo))}
    </div>
  );

  return (
    <RegisterStyles>
      <div className="login-section">
        <div className="login-section-logo">
          <img className="logo" src="/images/logo.svg" alt="Somn" />
        </div>
        <form onSubmit={onSubmit}>
          <h1>Sign Up</h1>

          <InputLabel htmlFor="name">Name</InputLabel>
          <Field component={TextField} label="Name" type="text" name="name" fullWidth required />

          <InputLabel htmlFor="email">Email</InputLabel>
          <Field component={TextField} label="Email" type="text" name="email" fullWidth required />

          <InputLabel htmlFor="password">Password</InputLabel>
          <Field component={TextField} label="Password" type="password" name="password" fullWidth required />

          <InputLabel htmlFor="password_confirmation">Re-Type Password</InputLabel>
          <Field component={TextField} label="Password Confirmation" type="password" name="password_confirmation" fullWidth required />

          <Button as="input" buttonType="primary" type="submit" value="Register" disabled={pristine || invalid || submitting} />
          <span className="status">
            {failed && <span className="error">Unable to register a new account.</span>}
            {submitting && <Spinner />}
          </span>
          {OAUTH.canRegister && OAUTH.providers.size > 0 && oauthRow}
          <p>
            <Link to="/signin">Already have an account?</Link>
          </p>
        </form>
        <footer className="login-section-footer">
          &copy; {new Date().getFullYear()} All Rights Reserved. <Link to="/privacy">Privacy</Link> and <Link to="/terms">Terms</Link>.
        </footer>
      </div>

      <div className="sleep-section">
        {/* <h1>Explore your sleep.</h1>
        <p>Our radically simple sleep assessment is rooted in 50 years of research.</p>
        <Button as="a" buttonType="secondary" href="https://rndresearchteam.qualtrics.com/jfe/form/SV_40HEXC8ht8xJtB3/">
          Start Assessment
        </Button> */}
      </div>
    </RegisterStyles>
  );
};

RegisterView.propTypes = propTypes;
RegisterView.defaultProps = defaultProps;

export default RegisterView;
