// To Do figure out how to translate this into Object of colors
// import axios from 'axios';
//
// var syncColors = [];
// var colors = {};
//
// axios.get('https://projects.invisionapp.com/dsm-export/prota-ventures/somn-brand/style-data.json?exportFormat=list&key=rJ3Hzao_N')
//   .then(function(response) {
//     console.log(response.data.list.colors);
//     syncColors = response.data.list.colors;
//
//   })
//   .catch(function(error) {
//     console.log(error);
//   });

const theme = {
  color: {
    primary: {
      lighter: '#7E93A4',
      light: '#536F86',
      default: '#294B68',
      dark: '#1C3449',
      darker: '#101D29',
    },
    secondary: {
      lighter: '#FBCFCB',
      light: '#FAC4BA',
      default: '#F79F98',
      dark: '#D66D63',
      darker: '#7B4F4B',
    },
    tertiary: {
      lighter: '#6CB2B4',
      light: '#3B989B',
      default: '#0B7F82',
      dark: '#086568',
      darker: '#064C4E',
    },
    neutral: {
      lighter: '#F9F4EB',
      light: '#F6ECDE',
      default: '#F0E0C8',
      dark: '#B4A896',
      darker: '#776F63',
    },
    tan: {
      lighter: '#DECBC2',
      light: '#CEB1A4',
      default: '#BE9886',
      dark: '#8F7264',
      darker: '#5E4B42',
    },
    forest: {
      lightest: '#D4D9D6',
      lighter: '#7A8A82',
      light: '#4E6358',
      default: '#223C2F',
      dark: '#1B3025',
      darker: '#14241C',
    },
    utility: {
      error: '#D26261',
      warning: '#D1BD57',
      info: '#5D7EB7',
      success: '#75A072',
    },
    factor: {
      mind: '#43466B',
      body: '#754A48',
      social: '#4D8A8C',
      routine: '#A89776',
      environment: '#4E6358',
    },

    text: {},
    bg: {},
    disabled: '#CCCCCC',
    whiteOffset: '#EEEEEE',
    white: '#ffffff',
    grayLighter: '#CCCCCC',
    error: '#EE0000',
  },
  font: {
    serif: {
      light: '"Recoleta W05 Light", sans-serif',
      regular: '"Recoleta W05 Regular", sans-serif',
      medium: '"Recoleta W05 Medium", sans-serif',
      bold: '"Recoleta W05 SemiBold", sans-serif',
    },
    sans: '"Source Sans Pro", sans-serif',
    mono: '"Source Code Pro", monospace',
  },
  text: {
    fontSize: 18,
    lineHeight: 24,
  },
  button: {
    borderRadius: 3,
  },
  screen: {},
  section: {},
  padding: {
    small: '1rem',
    base: '2rem',
    large: '5rem',
  },
};

theme.color.action = theme.color.secondary.default;
theme.color.actionHover = theme.color.secondary.light;

theme.color.text.dark = theme.color.primary.darker;
theme.color.text.light = theme.color.neutral.lighter;

theme.color.bg.primary = theme.color.primary.default;
theme.color.bg.secondary = theme.color.secondary.default;
theme.color.bg.dark = theme.color.primary.dark;
theme.color.bg.darker = theme.color.primary.darker;
theme.color.bg.light = theme.color.neutral.light;
theme.color.bg.lighter = theme.color.neutral.lighter;

export default theme;
