import styled from 'styled-components';
import theme from '../../theme';
import device from '../../device';

const TextTitle = styled.h1`
  color: inherit;
  font-family: ${theme.font.serif.light};
  font-weight: normal;
  font-size: 40px;
  line-height: 1.2em;
  margin-top: 0;

  @media ${device.medium} {
    font-size: 48px;
  }

  ${({ color }) => {
    switch (color) {
      case 'dark':
        return `
          color: ${theme.color.text.dark}
        `;
      case 'light':
        return `
          color: ${theme.color.text.light}
        `;
      default:
      case color:
        return `
          color: ${color}
        `;
    }
  }};

  ${({ margin }) => {
    switch (margin) {
      case 'top':
        return `
          margin-top: 1em;
          margin-bottom: 0;
        `;
      default:
      case 'bottom':
        return `
          margin-top: 0;
          margin-bottom: 1em;
        `;
      case 'none':
        return `
          margin: 0;
         `;
    }
  }};
`;

export default TextTitle;
