import { ResourcefulReducer } from '../../util';
import ACTIONS from '../actions';
import PostModel from '../models/post_model';

const PostReducer = new ResourcefulReducer('posts', PostModel, {
  actionTypes: [ACTIONS.LIST_POSTS.success],
});

global.post_reducer = PostReducer;

export default PostReducer;
