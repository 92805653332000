import styled from 'styled-components';
import theme from '../../theme';
import device from '../../device';

const TextHeading = styled.h2`
  font-family: ${theme.font.serif.regular};
  font-weight: normal;
  color: inherit;
  line-height: 1.2;

  ${({ color }) => {
    switch (color) {
      case 'dark':
        return `
          color: ${theme.color.text.dark}
        `;
      case 'light':
        return `
          color: ${theme.color.text.light}
        `;
      default:
      case color:
        return `
          color: ${color}
        `;
    }
  }};

  ${({ size }) => {
    switch (size) {
      case 'large':
      default:
        return `
          font-family: ${theme.font.serif.light};
          font-size: 2rem;

          @media ${device.small} {
            font-size: 2rem;
          }

          @media ${device.medium} {
            font-size: 3vw;
          }

          @media ${device.large} {
            font-size: 3vw;
          }

          @media ${device.xlarge} {
            font-size: 4vw;
          }
        `;
      case 'medium':
        return `
          font-size: 1.35rem;

          @media ${device.small} {
            font-size: 1.35rem;
          }

          @media ${device.medium} {
            font-size: 2vw;
          }

          @media ${device.large} {
            font-size: 2vw;
          }

          @media ${device.xlarge} {
            font-size: 2vw;
          }
        `;
      case 'small':
        return `
          font-size: 1rem;
          text-transform: uppercase;

          @media ${device.small} {
            font-size: 1rem;
          }

          @media ${device.medium} {
            font-size: 1.2vw;
          }

          @media ${device.large} {
            font-size: 1.2vw;
          }

          @media ${device.xlarge} {
            font-size: 1.2vw;
          }
        `;
    }
  }};

  ${({ margin }) => {
    switch (margin) {
      case 'top':
        return `
          margin-top: 1em;
          margin-bottom: 0;
        `;
      default:
      case 'bottom':
        return `
          margin-top: 0;
          margin-bottom: 1em;
        `;
      case 'none':
        return `
          margin: 0;
        `;
      case 'both':
        return `
          margin-top: 1em;
          margin-bottom: 1em;
        `;
    }
  }};
`;

export default TextHeading;
