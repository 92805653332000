import { fromJS } from 'immutable';

const Copy = fromJS({
  nighttimeSleep: {
    factors: {
      high:
        'Here’s the bad news: Your sleep isn’t great and you’re not exactly thrilled with it. But, here’s the good news: Real, rejuvenating sleep is possible. The first step is getting to the root of the problem. Let’s see which Sleep Factors affect you most and explore how you can overcome them.',
      medium:
        'Your sleep isn’t always rejuvenating or satisfying. You may experience minor insomnia and night wakings on occasion. And, it’s sometimes difficult for you to fall asleep. While they’re intermittent, these experiences take a toll on your energy, mood, and physical health.',
      low:
        'Good news! Your sleep health is high. You rarely experience problems with falling asleep and night wakings. Your sleep is rejuvenating your mind and body so that you can live healthily and happily. Now it’s time to optimize and elevate your rest.',
    },
    insomniaSeverityIndex: 'TODO',
    sleepOnsetLatency: 'TODO',
    nighttimeWalkings: 'TODO',
  },
  daytimeFunction: {
    factors: {
      high:
        'You often feel tired when you’re awake - so much so that you fall asleep during the day (been there). This fatigue hinders your ability to be productive, creative, and present. It can also keep you from doing everything you’d like with the energy you wish you had, which is a strong signal that you’re not getting enough restorative sleep.',
      medium:
        'You sometimes feel tired when you’re awake. Hey, it happens - and when it does, it can sometimes hinder your ability to be productive, creative, and present. You may not feel like your best self (trust us, you are regardless). But, feeling fatigued could also signal that you’re not getting enough deep, restorative sleep.',
      low:
        'You have great daytime performance. Take a bow! Then run a mile, lead a meeting, and host a party! Because you’re one of the rare few who has enough energy to be productive, creative, and present throughout the day. This is a sign that you’re getting restful, restorative sleep.',
    },
    epworthDaytimeSleepiness: 'TODO',
    isiQ4: 'TODO',
    energyNeed: 'TODO',
  },
  mind: {
    factors: {
      high:
        'Are you a NASCAR driver? Because your mind is racing. The stresses of the day frequently interferes with your much needed slumber. Luckily, there are tools and practices that can help you pump the brakes on racing thoughts and create calm.',
      medium:
        'Sometimes, the stresses of the day impact your sleep. You occasionally deal with anxiety and feel like there’s an endless track of racing thoughts interfering with your sleep. Luckily, there are tools and practices that can help you pump the brakes and create calm.',
      low:
        'For some people, stress and anxiety disrupts their sleep. But not you! You can relax easily and calm your worries before bed. Keep it up!',
    },
    anxiety: {
      title: 'Anxiety',
      subtitle: 'Unable to rest because of concerns and worries',
    },
    stress: {
      title: 'Stress',
      subtitle: 'Restlessness and nervousness that keeps you up at night',
    },
    negativeMood: {
      title: 'Negative Mood',
      subtitle: 'Listless and hopeless feelings interfere with your rest',
    },
    overactiveMind: {
      title: 'Overactive Mind',
      subtitle: 'Struggling to sleep because of racing thoughts',
    },
  },
  body: {
    factors: {
      high:
        'Chronic aches. Unforgiving pains. You know you it all too well. During the assessment, you were screened for several physiological drivers as well as sleep apnea and restless leg syndrome. It appears that physiological issues significantly impact your sleep. Don’t fret - we’re here to help you get the relief and the sleep you deserve.',
      medium:
        'Find yourself waking up because of aches? Or, struggling to get comfortable in your bed? You’re not alone. During the assessment, you were screened for several physiological drivers as well as sleep apnea and restless leg syndrome. It appears that physiological issues sometimes disrupt your sleep. We’ll share some tips and tools that can help.',
      low:
        'Check you out - your physical health doesn’t interfere with your sleep. AT. ALL. During the assessment, you were screened for several physiological drivers as well as sleep apnea and restless leg syndrome. And we’re happy to say you don’t appear to have these issues!',
    },
    apnea: {
      title: 'Snoring or Sleep Apnea',
      subtitle: 'Loud snoring and frequent breaks in breathing while asleep',
    },
    restlessLegs: {
      title: 'Restless Leg Syndrome',
      subtitle: 'Experiencing an irresistible urge to move your legs while in bed',
    },
    pain: {
      title: 'Pain',
      subtitle: 'Suffering from uncomfortable sensations that interferes with your sleep',
    },
    physicalDiscomfort: {
      title: 'Physical Discomfort',
      subtitle: 'Unable to rest because of unpleasant, distracting physical irritation',
    },
    arthritis: {
      title: 'TODO',
      subtitle: 'TODO',
    },
    ageSex: {
      title: 'TODO',
      subtitle: 'TODO',
    },
    menopause: {
      title: 'Menopause',
      subtitle: 'Sleep is disrupted by the physical and emotional transition that occurs as women age',
    },
    thoughtsAndFeelings: {
      title: 'TODO',
      subtitle: 'TODO',
    },
  },
  social: {
    factors: {
      high:
        'Work demands. Home life. Children. Oh, and pets. Social obligations often consume your day and get in the way of your rest.  Let’s work together to lighten the impact of those external constraints so you can get the rest you need.',
      medium:
        'Social obligations (like work demands, children, and pets) sometimes get in the way of your sleep. Let’s work together to consider the external constraints on your sleep and how you can lighten their impact.',
      low:
        'Obligations and relationships don’t get in the way of your sleep. You have a good degree of control over when you rest. One could even say you have mastered the delicate balance between sleep and your daily responsibilities. (Side note: What’s your secret?)',
    },
    childScore: {
      title: 'Children',
      subtitle: 'Caregiving responsibilities to children hinder restorative sleep',
    },
    apnea: {
      title: 'TODO',
      subtitle: 'TODO',
    },
    pregnant: {
      title: 'Pregnancy',
      subtitle: 'Sleep is disrupted by the physical and emotional changes that come with pregnancy',
    },
    workShift: {
      title: 'Work and School',
      subtitle: 'Obligations to your career or education get in the way of your work-life balance',
    },
    childDisrupts: {
      title: 'Sleep Interruptions from Children',
      subtitle: 'Your rest is  needs or behaviors of children that interfere with your rest',
    },
    workDisrupts: {
      title: 'Work Interferes with Sleep',
      subtitle: 'Daily demands in your career and education impede on your sleep',
    },
    bedPartnerDisrupts: {
      title: 'Bed Partner Interferes with Sleep',
      subtitle: 'Disruptive sounds or movement from your bed partner',
    },
    petDisrupts: {
      title: 'Pet Interferes with Sleep',
      subtitle: 'The noises and needs of pets interrupt sleep',
    },
  },
  routine: {
    factors: {
      high:
        'Routine might seem like a four-letter word (or a pipe dream), but it’s essential to good sleep. Right now, your rest is frequently disrupted by your daily activities. So, let’s focus on developing better, consistent habits that help you get the most out of your night (and your day).',
      medium:
        'A random late night, a necessary early morning - sometimes your schedule can be all over the place. Your sleep is occasionally disrupted by your daily activities and rituals. To improve your sleep, let’s first focus on developing better habits that help you get the most out of your night (and your day).',
      low:
      'Routine is one of the trickiest sleep factors. But, you’ve figured it out. Bravo! Your behaviors and choices set the stage for healthy sleep. Now, let’s build on your strong routine so you can get more out of your rest.',
    },
    bedtime: {
      title: 'Bedtime',
      subtitle: 'What time you usually go to bed and ',
    },
    exercise: {
      title: 'Exercise',
      subtitle: 'How often you work out and when',
    },
    caffiene: {
      title: 'Caffeine',
      subtitle: 'When and how much caffeine you consume each day',
    },
    alcohol: {
      title: 'TODO',
      subtitle: 'TODO',
    },
    haveTried: {
      title: 'TODO',
      subtitle: 'TODO',
    },
    wouldChange: {
      title: 'Your Sleep Goal',
      subtitle: 'The changes you’d like to make to your sleep',
    },
  },
  environment: {
    factors: {
      high: 'TODO',
      medium: 'TODO',
      low: 'TODO',
    },
    blackoutCurtains: {
      title: 'TODO',
      subtitle: 'TODO',
    },
    noiseMachine: {
      title: 'TODO',
      subtitle: 'TODO',
    },
    environment: {
      title: 'TODO',
      subtitle: 'TODO',
    },
  },
});

// Helper methods
export const getFactorCopy = (section, score) => {
  const factors = Copy.getIn([section, 'factors']);
  if (score <= 33) return factors.get('high');
  if (score <= 66) return factors.get('medium');
  return factors.get('low');
};

export const getFactorLabel = (percent) => {
  if (percent <= 33) return 'Not a';
  if (percent <= 66) return 'Minor';
  return 'Key';
};

export const getFactorImage = (percent) => {
  if (percent <= 11.11) return 'gauge-1.svg';
  if (percent <= 22.22) return 'gauge-2.svg';
  if (percent <= 33.33) return 'gauge-3.svg';
  if (percent <= 44.44) return 'gauge-4.svg';
  if (percent <= 55.55) return 'gauge-5.svg';
  if (percent <= 66.66) return 'gauge-6.svg';
  if (percent <= 77.77) return 'gauge-7.svg';
  if (percent <= 88.88) return 'gauge-8.svg';
  return 'gauge-9.svg';
};

export default Copy;
