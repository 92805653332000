// Firebase App is always required and must be first
import firebase from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyBPB6yCAA6k8VEf6JR6Ww9UWDgPVKx5yXA',
  authDomain: 'somn-demo.firebaseapp.com',
  databaseURL: 'https://somn-demo.firebaseio.com',
  projectId: 'somn-demo',
  storageBucket: 'somn-demo.appspot.com',
  messagingSenderId: 'somn',
};

firebase.initializeApp(config);

export const db = firebase.database;

export default firebase;
