/* eslint-disable filenames/match-exported */
import { combineImmutableReducers } from '../util';
import ACTIONS from './actions';

export const ContactFormStatusReducer = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.CONTACT_FORM_SUBMITTED:
      return 'submitting';
    case ACTIONS.CONTACT_FORM_SUCCESS:
      return 'success';
    case ACTIONS.CONTACT_FORM_FAILED:
      return 'failed';
    default:
      return state;
  }
};

const HubSpotFormReducer = combineImmutableReducers({
  contactFormStatus: ContactFormStatusReducer,
});

export default HubSpotFormReducer;

// Selectors
export const getContactFormStatus = (state) => state.get('contactFormStatus');
