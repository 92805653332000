import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Hook that wraps useState and leverages useRef and useEffect to track when
 * a component is mounted and short-circuit the setState calls if needed.
 */
export function useAsyncState(initialValue) {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [value, setValue] = useState(initialValue);
  return [
    value,
    (newValue) => {
      if (mounted.current) {
        setValue(newValue);
      }
    },
  ];
}

/**
 * Hook than handles the standard submitting/succeeded/failed state management
 */
export function useApiCall(call) {
  const [status, setStatus] = useAsyncState({ failed: false, submitting: false, success: false });
  const asyncCall = useCallback(
    async (args) => {
      setStatus({ failed: false, submitting: true, success: status.success });
      try {
        await call(args);
        setStatus({ submitting: false, failed: false, success: true });
      } catch (e) {
        setStatus({ submitting: false, failed: true, success: false });
      }
    },
    [call, status.success, setStatus],
  );
  return [asyncCall, status];
}
