import { connect } from 'react-redux';
import ContactFormView from './contact_form_view';
import { createMessage } from '../../actions';

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
  onSubmit: (values, dispatch) => dispatch(createMessage(values)),
});

const ContactFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactFormView);

export default ContactFormContainer;
