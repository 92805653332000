import { Map } from 'immutable';
import { combineImmutableReducers } from '../../util';
import StatusReducer from './status_reducer';
import ResponseReducer from './response_reducer';
import ArchivedReducer from './archived_reducer';
import PostReducer from './post_reducer';
import ProductReducer from './product_reducer';
import NavDrawerReducer from './nav_drawer_reducer';
import ResponseModel from '../models/response_model';

const REDUCERS = {
  archived: ArchivedReducer,
  formStatus: StatusReducer,
  navDrawer: NavDrawerReducer,
  post: PostReducer,
  product: ProductReducer,
  response: ResponseReducer,
};

const Reducer = combineImmutableReducers(REDUCERS);

export default Reducer;

// Selectors
// Note: these functions operate on state at the level of Somn, not the RootReducer.
export const getFormStatus = (state) => state.get('formStatus');
export const getArchives = (state) => ResponseReducer.selectors.getList(state.get('archived'));
export const getArchive = (state, id) => ResponseReducer.selectors.getOne(state.get('archived'), id);
export const getResponse = (state, id) => {
  const responseId = id || state.getIn(['archived', 'allIds', '0']);
  // The response reducer is no longer in use
  let response = getArchive(state, responseId);
  if (!response) response = ResponseReducer.selectors.getOne(state.get('response'), responseId);
  // FIXME: @valthon - hacky fix to rehydrate issue
  if (response instanceof Map) response = new ResponseModel(response);
  return response;
};
export const getPosts = (state) => PostReducer.selectors.getList(state.get('post'));
export const getProducts = (state) => ProductReducer.selectors.getList(state.get('product'));
export const getNavDrawer = (state) => state.get('navDrawer');
