import { ResourcefulReducer } from '../../util';
import ACTIONS from '../actions';
import ProductModel from '../models/product_model';

const ProductReducer = new ResourcefulReducer('products', ProductModel, {
  actionTypes: [ACTIONS.LIST_PRODUCTS.success],
});

global.product_reducer = ProductReducer;

export default ProductReducer;
