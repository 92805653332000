import { connect } from 'react-redux';
import SidebarView from './sidebar_view';
import { getResponse, getArchives } from '../../../reducer';
import { hideDrawer, toggleDrawer } from '../../actions';

const mapStateToProps = (state) => ({
  responses: getArchives(state),
  response: getResponse(state),
});

const mapDispatchToProps = (dispatch) => ({
  hideDrawer: () => dispatch(hideDrawer()),
  toggleDrawer: () => dispatch(toggleDrawer()),
});

const SidebarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidebarView);

export default SidebarContainer;
