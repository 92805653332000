import * as api from '../../api';
import { genericAPIActionTypes, genericAPIActionCreator } from '../../util';

const ACTIONS = {
  HIDE_DRAWER: 'Drawer:Hide',
  LIST_RESPONSE: genericAPIActionTypes('Somn:ListResponse'),
  LIST_RESPONSES: genericAPIActionTypes('Somn::ListResponses'),
  LIST_PRODUCTS: genericAPIActionTypes('Somn:ListProducts'),
  LIST_POSTS: genericAPIActionTypes('Somn:ListPosts'),
  CREATE_MESSAGE: genericAPIActionTypes('Somn:CreateMessage'),
  SHOW_DRAWER: 'Drawer:Show',
  TOGGLE_DRAWER: 'Drawer:Toggle',
};

export const showDrawer = () => ({
  type: ACTIONS.SHOW_DRAWER,
});

export const hideDrawer = () => ({
  type: ACTIONS.HIDE_DRAWER,
});

export const toggleDrawer = () => ({
  type: ACTIONS.TOGGLE_DRAWER,
});

export const fetchArchives = (token) => genericAPIActionCreator(() => api.fetchResponses(token), ACTIONS.LIST_RESPONSES, 'Failed to fetch responses');

export const fetchResponse = (id, token) => genericAPIActionCreator(() => api.fetchResponse(id, token), ACTIONS.LIST_RESPONSE, 'Failed to fetch response');

export const fetchProducts = () => genericAPIActionCreator(() => api.fetchProducts(), ACTIONS.LIST_PRODUCTS, 'Failed to fetch products');

export const fetchPosts = () => genericAPIActionCreator(() => api.fetchPosts(), ACTIONS.LIST_POSTS, 'Failed to fetch posts');

export const createMessage = (values) => genericAPIActionCreator(() => api.createMessage(values), ACTIONS.CREATE_MESSAGE, 'Failed to create message');

export default ACTIONS;
