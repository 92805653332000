import styled from 'styled-components';
import theme from '../../../common/theme';

const ContactFormStyles = styled.div`
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }

  .status {
    margin: 0 1em;
  }
  .error {
    color: ${(props) => props.theme.color.error};

    input[type='text'],
    input[type='email'],
    textarea {
      border: thin solid ${(props) => props.theme.color.error};
    }
  }

  a {
    color: ${theme.color.text.dark};
    text-decoration: none;
    border-bottom: 2px solid ${theme.color.secondary.default};

    &:hover {
    }
  }

  .link {
    color: #1c797f;
  }

  .intro-section {
    margin-bottom: 45px;

    h1 {
      font-weight: 300;
    }
  }

  label {
    float: left;
    font-size: 12px;
    padding-left: 6px;
  }

  input[type='text'],
  input[type='email'],
  textarea {
    font-size: 16px;
    font-family: inherit;
    font-weight: bold;
    outline: none;
    color: #304561;
    padding: 15px;
    border-radius: 6px;
    border: none;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: rgba(252, 247, 240, 1);
  }

  .row {
    padding: 0;
  }

  h3 {
    font-weight: bold;
    font-size: 1.2em;
  }
`;

export default ContactFormStyles;
