import styled from 'styled-components';
import theme from '../../../common/theme';
import device from '../../../common/device';

const DashboardStyles = styled.div`
  background-color: ${theme.color.white};

  @media ${device.medium} {
    display: flex;
    flex-direction: row;
  }

  .dashboard-container {
    @media ${device.medium} {
      width: calc(100vw - 250px);
    }
  }
`;

export default DashboardStyles;
