import { mockResourcefulAPI } from '../../util/mock_api';
import * as User from './user';
import POSTS from './mocks/posts';
import PRODUCTS from './mocks/products';
import RESPONSES from './mocks/responses';

export const { signIn, OAUTH, finishSignIn, register, confirmEmail, forgotPassword, resetPassword, updateCurrentUser } = User;

const POST_API = mockResourcefulAPI('post', POSTS);
export const fetchPosts = POST_API.list;

const PRODUCT_API = mockResourcefulAPI('product', PRODUCTS);
export const fetchProducts = PRODUCT_API.list;

const RESPONSE_API = mockResourcefulAPI('response', RESPONSES);
export const fetchResponse = RESPONSE_API.get;
export const fetchResponses = RESPONSE_API.list;

const MESSAGE_API = mockResourcefulAPI('message', {});
export const createMessage = MESSAGE_API.create;
