import styled from 'styled-components';
import theme from '../../theme';
import device from '../../device';

const Text = styled.div`
  color: inherit;
  font-family: ${theme.font.serif.regular};
  font-weight: normal;
  line-height: 1.5;
  vertical-align: baseline;

  ${({ color }) => {
    switch (color) {
      case 'dark':
        return `
          color: ${theme.color.text.dark}
        `;
      case 'light':
        return `
          color: ${theme.color.text.light}
        `;
      default:
      case color:
        return `
          color: ${color}
        `;
    }
  }};

  ${({ size }) => {
    switch (size) {
      case 'large':
        return `
          font-size: 1.35rem;

          @media ${device.small} {
            font-size: 1.35rem;
          }

          @media ${device.medium} {
            font-size: 2vw;
          }

          @media ${device.large} {
            font-size: 2vw;
          }

          @media ${device.xlarge} {
            font-size: 2vw;
          }
        `;
      default:
      case 'medium':
        return `
          font-size: 1rem;

          @media ${device.small} {
            font-size: 1rem;
          }

          @media ${device.medium} {
            font-size: 20px;
          }

          @media ${device.large} {
            font-size: 1.8vw;
          }

          @media ${device.xlarge} {
            font-size: 1.45vw;
          }
        `;
      case 'small':
        return `
          font-size: 0.9rem;

          @media ${device.small} {
            font-size: 0.8rem;
          }

          @media ${device.medium} {
            font-size: 1.5vw;
          }

          @media ${device.large} {
            font-size: 1.1vw;
          }

          @media ${device.xlarge} {
            font-size: 1.1vw;
          }
        `;
      case 'xsmall':
        return `
          font-size: 0.8rem;
          line-height: 1.4;

          @media ${device.small} {
            font-size: 0.65rem;
          }

          @media ${device.medium} {
            font-size: 0.9vw;
          }

          @media ${device.large} {
            font-size: 0.9vw;
          }

          @media ${device.xlarge} {
            font-size: 0.8vw;
          }
        `;
    }
  }};


  ${(props) =>
    props.weight === 'bold' &&
    `
      font-family: ${theme.font.serif.bold};
  `};

  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `};

  ${(props) =>
    props.margin === 'top' &&
    `
    margin-top: 1em;
    margin-bottom: 0;
  `};
  ${(props) =>
    props.margin === 'bottom' &&
    `
    margin-top: 0;
    margin-bottom: 1em;
  `};
  ${(props) =>
    props.margin === 'none' &&
    `
    margin: 0;
  `};
`;

export default Text;
