import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { reduxForm, Field, TextField } from '../../../common/form';
import Button from '../../../common/components/button';
import ContactFormStyles from './contact_form_styles';

const REQUIRED = 'Required';
const INVALID_EMAIL_FORMAT = 'Invalid email format';

const validate = (values, _props) => {
  const errors = {};

  if (!values.get('email')) {
    errors.email = REQUIRED;
  } else if (!validator.isEmail(values.get('email'))) {
    errors.email = INVALID_EMAIL_FORMAT;
  }

  if (!values.get('message')) {
    errors.message = REQUIRED;
  }

  return errors;
};

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
};

const defaultProps = {
  pristine: true,
  invalid: false,
};

const ContactFormView = ({ handleSubmit, onSubmit, pristine, invalid }) => (
  <ContactFormStyles>
    <h3>Can&#39;t find what you&#39;re looking for?</h3>
    <p>Tell us more about what you need and we&#39;ll help you find a solution</p>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field id="email" component={TextField} label="Email" type="text" name="email" required />
      <Field id="message" component={TextField} label="Fill out your message..." type="text" name="message" required textarea />

      <Button as="input" buttonType="secondary" type="submit" value="Reach Out" disabled={pristine || invalid} />
    </form>
  </ContactFormStyles>
);

ContactFormView.propTypes = propTypes;
ContactFormView.defaultProps = defaultProps;

export default reduxForm({ form: 'contactForm', validate })(ContactFormView);
