import { connect } from 'react-redux';
import FullReportsView from './full_report_view';
import { getResponse, getCurrentUser } from '../../../../reducer';

const mapStateToProps = (state, { match: { params: { id } } }) => ({
  user: getCurrentUser(state),
  response: getResponse(state, id),
});

const mapDispatchToProps = {
  //
};

const FullReportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FullReportsView);

export default FullReportsContainer;
