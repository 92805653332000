import styled from 'styled-components';
import theme from '../../theme';

const LogoStyles = styled.div`
  width: ${(props) => props.width || '100%'};

  svg {
    display: block;
    pointer-events: none;
  }

  path {
    fill: ${(props) => props.color || theme.color.primary.default};
  }
`;

export default LogoStyles;
