import styled from 'styled-components';
import theme from '../../../common/theme';
import device from '../../../common/device';

const RegisterStyles = styled.div`
  background-repeat: no-repeat;
  background-size: auto;
  box-sizing: border-box;
  height: 100vh;

  @media ${device.medium} {
    display: flex;
    flex-direction: row;
  }

  * {
    box-sizing: inherit;
  }

  .status {
    margin: 0 1em;
  }

  .error {
    color: ${(props) => props.theme.color.error};

    input[type='text'],
    input[type='password'] {
      border: thin solid ${(props) => props.theme.color.error};
    }
  }

  .oauth-row {
    margin: auto;
    width: fit-content;
    padding: 1em;
  }

  .login-section-logo {
    width: 100px;
    margin: 0 auto;

    @media ${device.medium} {
      width: 15vw;
    }
  }

  .oauth-button {
    margin: 5px;
    color: ${(props) => props.theme.color.action};
    &:hover {
      color: ${(props) => props.theme.color.actionHover};
    }
  }

  a {
    color: ${theme.color.text.dark};
    text-decoration: none;
    border-bottom: 2px solid ${theme.color.secondary.default};

    &:hover {
    }
  }

  .link {
    color: #1c797f;
  }

  .intro-section {
    margin-bottom: 45px;

    h1 {
      font-weight: 300;
    }
  }

  label {
    float: left;
    font-size: 12px;
    padding-left: 6px;
  }

  input[type='text'],
  input[type='password'] {
    color: #304561;
    padding: 15px;
    border-radius: 6px;
    border: none;
    font-size: 16px;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #ead9cc;
  }

  input[type='submit'] {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .row {
    padding: 0;
  }

  .login-section,
  .sleep-section {
    width: 100%;
    min-height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;

    @media ${device.medium} {
      padding-left: 5vw;
      padding-right: 5vw;
      width: 50%;
    }

    @media ${device.large} {
      padding-left: 10vw;
      padding-right: 10vw;
    }
  }

  .login-section {
    padding-top: 2rem;
    background-color: #fbf4eb;

    @media ${device.medium} {
      padding-top: 5rem;
    }
  }

  .sleep-section {
    display: none;
    padding-top: 10vw;
    position: relative;

    @media ${device.medium} {
      display: block;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 50vh;
        background-color: #f79f98;
        z-index: -1;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 50vh;
        top: 50vh;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('/images/bg-waves.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        z-index: -1;
      }
    }

    @media ${device.xlarge} {
      background-size: contain;
    }

    h1 {
      font-weight: 300;
    }
  }
`;

export default RegisterStyles;
