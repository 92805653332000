import { connect } from 'react-redux';
import DashboardView from './dashboard_view';
import { getPosts, getProducts, getCurrentUser, getArchives, getResponse } from '../../../reducer';
import { fetchProducts, fetchPosts, fetchArchives, fetchResponse } from '../../actions';

const mapStateToProps = (state, ownProps) => {
  const { match: { params: { id } } } = ownProps;

  return {
    user: getCurrentUser(state),
    posts: getPosts(state),
    products: getProducts(state),
    response: getResponse(state, id),
    responseId: id,
    archives: getArchives(state),
  };
};

const mapDispatchToProps = {
  fetchPosts,
  fetchProducts,
  fetchResponse,
  fetchArchives,
};

const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardView);

export default DashboardContainer;
