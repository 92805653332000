import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import SignInStyles from '../sign_in/sign_in_styles';
import { Field, TextField } from '../../../common/form';
import Spinner from '../../../common/components/spinner';
import { useApiCall } from '../../../util/hooks';
import Button from '../../../common/components/button';

const propTypes = {
  signedIn: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
};

const defaultProps = {
  pristine: false,
  invalid: false,
};

const ForgotPasswordView = ({ signedIn, handleSubmit, pristine, invalid }) => {
  const [onSubmit, { submitting, failed, success }] = useApiCall(handleSubmit);

  if (signedIn) {
    // Redirect to profile page if already signed in...
    return <Redirect to="/profile" />;
  }

  let content;
  if (success) {
    content = <p>Check your inbox for an email with a password reset link.</p>;
  } else {
    content = (
      <Fragment>
        <Field component={TextField} label="Email" type="text" name="email" fullWidth required />
        <Button as="input" buttonType="primary" type="submit" value="Request Password Reset" disabled={pristine || invalid || submitting} />
        <span className="status">
          {failed && <span className="error">Please try again...</span>}
          {submitting && <Spinner />}
        </span>
      </Fragment>
    );
  }

  return (
    <SignInStyles>
      <div className="login-section">
        <div className="login-section-logo">
          <img className="logo" src="/images/logo.svg" alt="Somn" />
        </div>
        <form onSubmit={onSubmit}>
          <h3>Forgot your password?</h3>
          {content}
          <p>
            <Link to="/signin">Sign in</Link>
            <span style={{ padding: '0 1em' }}>|</span>
            <Link to="/register">Don&apos;t have an account?</Link>
          </p>
        </form>

        <footer className="login-section-footer">
          &copy; {new Date().getFullYear()} All Rights Reserved. <Link to="/privacy">Privacy</Link> and <Link to="/terms">Terms</Link>.
        </footer>
      </div>

      <div className="sleep-section" />
    </SignInStyles>
  );
};

ForgotPasswordView.propTypes = propTypes;
ForgotPasswordView.defaultProps = defaultProps;

export default ForgotPasswordView;
