import { reducer as FormReducer } from 'redux-form/immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { combineImmutableReducers } from './util';
import SessionReducer, * as SessionSelect from './session/reducer';
import SomnReducer, * as SomnSelectors from './somn/reducer';
import GlobalSettingsReducer, * as GlobalSettingsSelectors from './global_settings/reducer';
import HubSpotFormReducer, { getContactFormStatus } from './hub_spot_form/reducer';
import { ContentReducer, ContentSelectors } from './vendor/flamelink';

const Reducer = (history) =>
  combineImmutableReducers({
    router: connectRouter(history),
    content: ContentReducer,
    form: FormReducer,
    session: SessionReducer,
    somn: SomnReducer,
    globalSettings: GlobalSettingsReducer,
    hubSpotForm: HubSpotFormReducer,
  });

export default Reducer;

// Flamelink Content
export const getPages = (state) => ContentSelectors.getPages(state.get('content'));
export const getPageByTitle = (state, title) => ContentSelectors.getPageByTitle(state.get('content'), title);
export const getSnippets = (state) => ContentSelectors.getSnippets(state.get('content'));
export const getSnippetByTitle = (state, title) => ContentSelectors.getSnippetByTitle(state.get('content'), title);

// HubSpot Selectors
export const getHubSpotContactFormStatus = (state) => getContactFormStatus(state.get('hubSpotForm'));

// Archives are inactive or past assessment results
export const getArchives = (state) => SomnSelectors.getArchives(state.get('somn'));
export const getArchive = (state, id) => SomnSelectors.getArchive(state.get('somn'), id);
// Response acts as the active assessment results
export const getResponse = (state, id) => SomnSelectors.getResponse(state.get('somn'), id);
export const getProducts = (state) => SomnSelectors.getProducts(state.get('somn'));
export const getPosts = (state) => SomnSelectors.getPosts(state.get('somn'));
export const getSomnFormStatus = (state) => SomnSelectors.getFormStatus(state.get('somn'));
export const getGlobalSetting = (state, key, notSetValue = undefined) => GlobalSettingsSelectors.getSetting(state.get('globalSettings'), key, notSetValue);
export const getFlashList = (state) => SessionSelect.getFlashList(state.get('session'));
export const getCurrentUser = (state) => SessionSelect.getCurrentUser(state.get('session'));
export const getTreatment = (state, experiemnt, defaultTreatment) => SessionSelect.getTreatment(state.get('session'), experiemnt, defaultTreatment);
export const getNavDrawer = (state) => SomnSelectors.getNavDrawer(state.get('somn'));
