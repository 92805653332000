import React from 'react';
import { getWordpressUrl } from '../../wp';
import FooterStyles from './footer_styles';
import { Text } from '../../../common/components/text';

const FooterView = () => (
  <FooterStyles>
    <div className="wrapper">
      <div className="col-logo">
        <a href={getWordpressUrl('/')}>
          <img id="logo" className="logo" src="../images/logo.svg" alt="Somn" />
        </a>
      </div>

      <div className="col-links">
        <Text size="small">
          <ul>
            <li>
              <a href="/">Take Assessment</a>
            </li>
            <li>
              <a href="/">Knowledge Base</a>
            </li>
            <li>
              <a href="/">Sleep Resources</a>
            </li>
            <li>
              <a href="/">Sleep Stories</a>
            </li>
          </ul>
        </Text>
      </div>

      <div className="col-links">
        <Text size="small">
          <ul>
            <li>
              <a href="/">Terms of Use</a>
            </li>
            <li>
              <a href="/">Privacy Policy</a>
            </li>
          </ul>
        </Text>
      </div>

      <div className="col-newsletter" />
    </div>

    <div className="last">
      <Text size="small">© 2019 Copyright Somn. All rights reserved.</Text>
    </div>
  </FooterStyles>
);

export default FooterView;
