import React from 'react';
import PropTypes from 'prop-types';
import NavDrawerStyles from './nav_drawer_styles';

const propTypes = {
  children: PropTypes.node,
  navDrawer: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  navDrawer: 'hide',
};

const NavDrawerView = ({ navDrawer, children }) => (
  <NavDrawerStyles show={navDrawer}>
    {children}
  </NavDrawerStyles>
);

NavDrawerView.propTypes = propTypes;
NavDrawerView.defaultProps = defaultProps;

export default NavDrawerView;
