import styled from 'styled-components';
import theme from '../../../common/theme';
import LogoStyles from '../../../common/components/logo/logo_styles';
import device from '../../../common/device';
import NavDrawerStyles from '../nav_drawer/nav_drawer_styles';

const SidebarStyles = styled.div`
  background-color: ${theme.color.white};
  padding: 1rem;
  width: 100%;
  box-shadow: inset -1px 0 0 ${theme.color.forest.lightest};
  box-sizing: border-box;

  @media ${device.medium} {
    padding: 3rem 2rem;
    width: 250px;
  }

  .sidebar-nav {
    display: flex;
    flex-direction: column;

    a {
      border-radius: 0.35em;
      color: ${theme.color.text.dark};
      font-family: ${theme.font.serif.bold};
      letter-spacing: 0.025em;
      line-height: 1em;
      margin-bottom: 0.15em;
      padding: 0.35em 0.75em 0.25em;
      text-decoration: none;
      text-transform: uppercase;

      &.active {
        background-color: ${theme.color.action};
        color: ${theme.color.text.light};
      }

      &:hover {
        background-color: ${theme.color.actionHover};
        color: ${theme.color.text.light};
      }

      &.disabled {
        color: ${theme.color.disabled};
        cursor: not-allowed;

        &:hover {
          background-color: inherit;
          color: ${theme.color.disabled};
        }
      }
    }
  }

  .logo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media ${device.medium} {
      margin-bottom: 2rem;
    }
  }

  .menu-toggle {
    width: 37px;
    cursor: pointer;

    @media ${device.medium} {
      display: none;
    }

    svg {
      display: block;
      path {
        fill: ${theme.color.primary.default};
      }
    }
  }

  ${LogoStyles} {
    width: 100px;

    @media ${device.medium} {
      width: 75%;
    }
  }

  .menu-wrapper {
    display: none;
    position: absolute;
    background-color: ${theme.color.white};
    z-index: 100;
    padding: 1rem;
    width: 100%;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;

    &[data-menu-open="true"] {
      display: block;
    }

    @media ${device.medium} {
      background-color: initial;
      display: relative;
      position: relative;
      z-index: initial;
      padding: initial;
      width: initial;
      top: initial;
      left: initial;
      right: initial;
      bottom: initial;

      &[data-menu-open="false"] {
        display: block;
      }
    }
  }

  ${NavDrawerStyles} {
    @media ${device.onlySmall} {
      background: linear-gradient(to bottom, ${theme.color.white}, ${theme.color.neutral.lighter});
      top: 60px;
    }
  }
`;

export default SidebarStyles;
