import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import SignInStyles from './sign_in_styles';
import { Field, TextField, InputLabel } from '../../../common/form';
import Button from '../../../common/components/button';
import Spinner from '../../../common/components/spinner';
import { useApiCall } from '../../../util/hooks';
import { Text } from '../../../common/components/text';
// import { OAUTH } from '../../../api';

const propTypes = {
  signedIn: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      returnTo: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ pathname: PropTypes.string })]),
    }),
  }).isRequired,
};

const defaultProps = {
  pristine: false,
  invalid: false,
};

// const oauthButton = (provider, returnTo) => (
//  <a key={provider} href={OAUTH.getUrl(provider, returnTo)} className="oauth-button">
//    {provider === 'developer' ? <i className="fas fa-user-secret fa-2x" /> : <i className={`fab fa-${provider} fa-5x`} />}
//  </a>
// );

const DEFAULT_RETURN_TO = '/dashboard';

const SignInView = ({ signedIn, handleSubmit, pristine, invalid, location: { state } }) => {
  const [onSubmit, { submitting, failed }] = useApiCall(handleSubmit);
  const returnTo = (state && state.returnTo) || DEFAULT_RETURN_TO;

  if (signedIn) {
    return <Redirect to={returnTo} />;
  }

  // const oauthRow = (
  //   <div className="oauth-row">
  //     <span>Sign in with:</span>
  //     {OAUTH.providers.map((provider) => oauthButton(provider, returnTo))}
  //   </div>
  // );

  return (
    <SignInStyles>
      <div className="login-section">
        <div className="login-section-logo">
          <img className="logo" src="/images/logo.svg" alt="Somn" />
        </div>

        <form onSubmit={onSubmit}>
          <div className="intro-section">
            <h1>Sign In</h1>
            <p>
              Don&apos;t have a Somn account?
              <br />
              <Link to="/signup">Create an account.</Link>
            </p>
          </div>

          <InputLabel htmlFor="email">Email address</InputLabel>
          <Field id="email" component={TextField} label="Email" type="text" name="email" required />

          <InputLabel htmlFor="password">Password</InputLabel>
          <Field id="password" component={TextField} label="Password" type="password" name="password" required />

          <Button as="input" buttonType="primary" type="submit" value="Sign In" disabled={pristine || invalid || submitting} />

          <p>
            <Link to="/forgot">Forgot password?</Link>
          </p>
          <span className="status">
            {failed && <span className="error">Invalid credentials</span>}
            {submitting && <Spinner />}
          </span>
        </form>

        <footer className="login-section-footer">
          <Text size="small">&copy; {new Date().getFullYear()} All Rights Reserved. <Link to="/privacy">Privacy</Link> and <Link to="/terms">Terms</Link>.</Text>
        </footer>
      </div>

      <div className="sleep-section">
        <h1>Explore your sleep.</h1>
        <p>Our radically simple sleep assessment is rooted in 50 years of research.</p>
        <Button as="a" buttonType="secondary" href="https://rndresearchteam.qualtrics.com/jfe/form/SV_40HEXC8ht8xJtB3/">
          Start Assessment
        </Button>
      </div>
    </SignInStyles>
  );
};

SignInView.propTypes = propTypes;
SignInView.defaultProps = defaultProps;

export default SignInView;
