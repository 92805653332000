import React from 'react';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router/immutable';
import ErrorBoundary from './util/error_boundary';
import './app.css';
import theme from './common/theme';
import configureStore from './store';
import Flamelink from './vendor/flamelink';
import featureFlag from './util/feature_flag';
import Somn from './somn';
import LandingPage from './landing_page';
import { setTreatment } from './session/actions';

export const { store, history } = configureStore();

Flamelink.init(store);

global.setTreatment = (key, value) => store.dispatch(setTreatment(key, value));
global.showApp = () => global.setTreatment('showApp', 'party time!');
global.hideApp = () => global.setTreatment('showApp', false);

global.showApp();

const Root = featureFlag('showApp', Somn, LandingPage);

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Root />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>
);

export default App;
