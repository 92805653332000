import styled from 'styled-components';
import theme from '../../common/theme';

const RootStyles = styled.div`
  font-family: ${theme.font.serif.regular};
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }
`;

export default RootStyles;
