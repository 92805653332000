import styled from 'styled-components';

const ProfileStyles = styled.div`
  padding: 30px;
  p {
    margin-bottom: 0;
  }
  input {
    width: 300px;
  }
  .error {
    color: ${(props) => props.theme.color.error};

    input[type='text'],
    input[type='password'] {
      border: thin solid ${(props) => props.theme.color.error};
    }
  }

  label {
    float: left;
    font-size: 12px;
    padding-left: 6px;
  }

  input[type='text'],
  input[type='password'] {
    color: #304561;
    padding: 15px;
    border-radius: 6px;
    border: none;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #ead9cc;
    display: block;
    max-width: 25em;
    &[disabled] {
      background-color: inherit;
    }
  }
`;

export default ProfileStyles;
