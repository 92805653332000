import styled from 'styled-components';
// import theme from '../theme';

const MockAuthStyles = styled.div`
  margin: auto;
  width: fit-content;
  border: solid red 2px;
  padding: 1em;
`;

export default MockAuthStyles;
