import ACTIONS from '../actions';

const NavDrawerReducer = (state = 'hide', action) => {
  switch (action.type) {
    case ACTIONS.SHOW_DRAWER:
      return 'show';
    case ACTIONS.HIDE_DRAWER:
      return 'hide';
    case ACTIONS.TOGGLE_DRAWER:
      return state === 'hide' ? 'show' : 'hide';
    default:
      return state;
  }
};

global.post_reducer = NavDrawerReducer;

export default NavDrawerReducer;
