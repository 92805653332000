import styled from 'styled-components';
import theme from '../../../common/theme';
import device from '../../../common/device';

const FooterStyles = styled.div`
  background-color: ${theme.color.neutral.default};
  background-image: url('/images/icon-routine.svg');
  background-repeat: no-repeat;
  background-position: right bottom 1.5rem;
  background-size: 40vw;
  position: relative;
  bottom: 0;
  width: 100%;
  padding-top: 6vw;
  font-family: ${theme.font.serif.regular};
  color: ${theme.color.tan.dark};

  @media ${device.medium} {
    background-position: left bottom;
    background-size: 26vw;
  }

  &:after {
    background-color: ${theme.color.neutral.lighter};
    display: block;
    content: '';
    position: relative;
    width: 100%;
    height: 2rem;

    @media ${device.medium} {
      height: 5vw;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem;

    @media ${device.medium} {
      flex-wrap: nowrap;
    }
  }

  .logo {
    width: 50%;

    @media ${device.medium} {
      width: 80%;
    }
  }

  .col-logo {
    width: 100%;
    margin-bottom: 1rem;

    @media ${device.medium} {
      margin-bottom: 0;
      width: 40%;
    }
  }

  .col-links {
    width: 50%;

    @media ${device.medium} {
      width: 20%;
    }
  }

  .col-newsletter {
    width: 100%;

    @media ${device.medium} {
      width: 40%;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-bottom: 0.8em;
  }

  a {
    font-family: ${theme.font.serif.regular};
    color: ${theme.color.tan.dark};
    text-decoration: none;
  }

  .last {
    margin-top: 12vw;
    margin-bottom: 3vw;
    padding: 0 1rem;

    @media ${device.medium} {
      text-align: center;
    }
  }
`;

export default FooterStyles;
