import styled from 'styled-components';
import theme from '../../../common/theme';
import device from '../../../common/device';
import NavDrawerStyles from '../nav_drawer/nav_drawer_styles';

const HeaderStyles = styled.header`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
  position: relative;

  @media ${device.large} {
    padding: 0 2vw;
  }

  * {
    box-sizing: inherit;
  }

  .header-nav {
    font-size: 24px;

    @media ${device.medium} {
      font-size: 1.175rem;
    }

    @media ${device.large} {
      display: block;
    }

    a {
      display: block;
      color: ${theme.color.primary.default};
      font-family: ${theme.font.serif.bold};
      margin: 0 0.5rem 1rem;
      text-decoration: none;

      @media ${device.medium} {
        display: inline;
        margin: 0 1vw;
      }

      @media (hover: hover) {
        &:hover {
          border-bottom: 2px solid ${theme.color.secondary.dark};
        }
      }
    }
  }

  .menu-toggle {
    cursor: pointer;
    display: flex;
    justify-content: center;

    @media ${device.medium} {
      display: none;
    }

    svg {
      display: block;
      width: 32px;

      path {
        fill: ${theme.color.primary.default};
      }
    }
  }

  ${NavDrawerStyles} {
    @media ${device.onlySmall} {
      background-color: ${theme.color.secondary.default};
      background-image: linear-gradient(to bottom, ${theme.color.secondary.default}, ${theme.color.neutral.lighter});
      top: 50px;
    }
  }
`;

export default HeaderStyles;
