/* eslint-disable */
import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  title: undefined,
  content: undefined,
  excerpt: undefined,
  link: undefined,
  status: undefined,
  date: undefined,
  guid: undefined,
  slug: undefined,
  imageUrl: undefined,
};

class PostModel extends Record(DEFAULT_ARGS) {
  static _name = 'Somn::Post';
}

export default PostModel;
