import styled from 'styled-components';
import theme from '../../theme';

const Link = styled.a`
  display: inline-block;
  color: ${theme.color.text.dark};
  text-decoration: none;
  box-shadow: 0 2px 0 ${theme.color.action};
  cursor: pointer;

  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `};

  &:hover {
    color: ${theme.color.action};
    box-shadow: 0 2px 0 ${theme.color.actionHover};
  }
`;

export default Link;
