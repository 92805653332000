import { connect } from 'react-redux';
import ReportsView from './reports_view';
import { getArchives, getCurrentUser } from '../../../reducer';
import { fetchArchives } from '../../actions';


const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  responses: getArchives(state),
});

const mapDispatchToProps = {
  fetchArchives,
};

const ReportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsView);

export default ReportsContainer;
