import { connect } from 'react-redux';
import { getCurrentUser, getResponse } from '../../../reducer';
import RegisterForm from './register_form';
import { register } from '../../actions';
import ResponseModel from '../../../somn/models/response_model';

const mapStateToProps = (state) => {
  const response = getResponse(state) || new ResponseModel();
  return {
    signedIn: getCurrentUser(state).signedIn,
    initialValues: { name: response.name, email: response.email },
  };
};

const mapDispatchToProps = {
  onSubmit: (info) => register(info.toJS()),
};

const RegisterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterForm);

export default RegisterContainer;
