import styled from 'styled-components';
import theme from '../../../common/theme';

const SliderStyles = styled.div`
  border-radius: 24px;
  background: linear-gradient(
    to right,
    ${theme.color.utility.info},
    ${theme.color.utility.success},
    ${theme.color.utility.warning},
    ${theme.color.utility.error}
  );
  display: grid;
  grid-template-columns: 100%;
  position: relative;
  height: 24px;
  width: 100%;

  .sliderGraph {
    margin: 0 auto;
    position: relative;
    width: calc(100% - 54px);
  }

  .sliderGraphEl {
    align-items: center;
    background-color: ${theme.color.neutral.lighter};
    border-radius: 24px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    color: ${theme.color.text.dark};
    display: flex;
    justify-content: center;
    height: 20px;
    margin-top: 2px;
    margin-left: -25px;
    position: absolute;
    width: 50px;
    pointer-events: none;
  }
`;

export default SliderStyles;
