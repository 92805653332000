import { connect } from 'react-redux';
import HeaderView from './header_view';
import { getCurrentUser } from '../../../reducer';
import { toggleDrawer } from '../../actions';

const mapStateToProps = (state) => ({
  signedIn: getCurrentUser(state).signedIn,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDrawer: () => dispatch(toggleDrawer()),
});

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderView);

export default HeaderContainer;
