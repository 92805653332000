import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { getCurrentUser } from '../reducer';

const mapStateToProps = (state) => ({ signedIn: getCurrentUser(state).signedIn });

const RedirectWithoutAuth = (props) => {
  const { signedIn, component: Component, location } = props;
  return signedIn ? <Component {...props} /> : <Redirect to={{ pathname: '/signin', state: { returnTo: location } }} />;
};

RedirectWithoutAuth.propTypes = {
  signedIn: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired,
  location: PropTypes.shape({}).isRequired,
};

const ConnectedRedirectWithoutAuth = connect(mapStateToProps)(RedirectWithoutAuth);

const AuthenticatedRoute = ({ component, ...props }) => (
  <Route {...props} component={(innerProps) => <ConnectedRedirectWithoutAuth {...innerProps} component={component} />} />
);

AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default AuthenticatedRoute;
