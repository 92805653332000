import { ResourcefulReducer } from '../../util';
import ResponseModel from '../models/response_model';
import ACTIONS from '../actions';

const ResponseReducer = new ResourcefulReducer('response', ResponseModel, {
  actionTypes: [ACTIONS.LIST_RESPONSE.success],
});

global.response_reducer = ResponseReducer;

export default ResponseReducer;
