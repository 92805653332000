import React from 'react';
import PropTypes from 'prop-types';
import FactorGraphStyles from './factor_graph_styles';
import { getFactorImage, getFactorLabel } from '../../../../copy';

const propTypes = {
  percent: PropTypes.number,
};

const defaultProps = {
  percent: 0,
};

const FactorGraphView = ({ percent }) => (
  <FactorGraphStyles>
    <img src={`/images/${getFactorImage(percent)}`} alt="small factor" />
    <div className="factor-text" data-percent={percent}>
      {getFactorLabel(percent)}
      <br />
      Factor
    </div>
  </FactorGraphStyles>
);

FactorGraphView.propTypes = propTypes;
FactorGraphView.defaultProps = defaultProps;

export default FactorGraphView;
