import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, DateTimePicker } from 'material-ui-pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createTextMask } from 'redux-form-input-masks';

import FormSelect from './form_select';
import theme from '../theme';

const FieldTheme = createMuiTheme({
  palette: {
    primary: {
      light: theme.color.primary.default,
      main: theme.color.primary.default,
      dark: theme.color.primary.default,
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const statesDropdownSelect = [
  ['AL', 'Alabama'],
  ['AK', 'Alaska'],
  ['AS', 'American Samoa'],
  ['AZ', 'Arizona'],
  ['AR', 'Arkansas'],
  ['CA', 'California'],
  ['CO', 'Colorado'],
  ['CT', 'Connecticut'],
  ['DE', 'Delaware'],
  ['DC', 'District Of Columbia'],
  ['FM', 'Federated States Of Micronesia'],
  ['FL', 'Florida'],
  ['GA', 'Georgia'],
  ['GU', 'Guam'],
  ['HI', 'Hawaii'],
  ['ID', 'Idaho'],
  ['IL', 'Illinois'],
  ['IN', 'Indiana'],
  ['IA', 'Iowa'],
  ['KS', 'Kansas'],
  ['KY', 'Kentucky'],
  ['LA', 'Louisiana'],
  ['ME', 'Maine'],
  ['MH', 'Marshall Islands'],
  ['MD', 'Maryland'],
  ['MA', 'Massachusetts'],
  ['MI', 'Michigan'],
  ['MN', 'Minnesota'],
  ['MS', 'Mississippi'],
  ['MO', 'Missouri'],
  ['MT', 'Montana'],
  ['NE', 'Nebraska'],
  ['NV', 'Nevada'],
  ['NH', 'New Hampshire'],
  ['NJ', 'New Jersey'],
  ['NM', 'New Mexico'],
  ['NY', 'New York'],
  ['NC', 'North Carolina'],
  ['ND', 'North Dakota'],
  ['MP', 'Northern Mariana Islands'],
  ['OH', 'Ohio'],
  ['OK', 'Oklahoma'],
  ['OR', 'Oregon'],
  ['PW', 'Palau'],
  ['PA', 'Pennsylvania'],
  ['PR', 'Puerto Rico'],
  ['RI', 'Rhode Island'],
  ['SC', 'South Carolina'],
  ['SD', 'South Dakota'],
  ['TN', 'Tennessee'],
  ['TX', 'Texas'],
  ['UT', 'Utah'],
  ['VT', 'Vermont'],
  ['VI', 'Virgin Islands'],
  ['VA', 'Virginia'],
  ['WA', 'Washington'],
  ['WV', 'West Virginia'],
  ['WI', 'Wisconsin'],
  ['WY', 'Wyoming'],
];

// Example Implementation
// <FormSelect
//  name="state"
//  options={new List(states)}
//  />

// Input Text Masks
// https://renato-bohler.github.io/redux-form-input-masks/#/

const phoneMask = createTextMask({
  pattern: '(999) 999-9999',
});

const zipCodeMask = createTextMask({
  pattern: '99999-9999',
});

// Date Time Picker Render Helpers
// https://material-ui-pickers.firebaseapp.com/
// * These forms inputs remove native datepicker functionality
// * Formating of Date is Rails compatible
// * Formating occurs during save into redux store

const renderDatePicker = (props) => {
  const {
    showErrorsInline,
    input: { onChange, value },
    meta: { touched, error },
    id,
    ...others
  } = props;

  const showError = showErrorsInline || touched;

  return (
    <DatePicker
      id={id}
      keyboard
      clearable
      format="MM-dd-yyyy"
      placeholder="MM-DD-YYYY"
      mask={(val) => (val ? [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] : [])}
      error={!!(showError && error)}
      helperText={showError && error}
      value={value || null}
      onChange={onChange}
      fullWidth
      {...others}
    />
  );
};
renderDatePicker.propTypes = {
  showErrorsInline: PropTypes.bool,
  input: PropTypes.shape({ onChange: PropTypes.func, value: PropTypes.string }),
  meta: PropTypes.shape({ touched: PropTypes.bool, error: PropTypes.bool }),
  id: PropTypes.string.isRequired,
};
renderDatePicker.defaultProps = {
  showErrorsInline: false,
  input: { onChange: () => {}, value: undefined },
  meta: { touched: false, error: false },
};

const renderDateTimePicker = (props) => {
  const {
    showErrorsInline,
    input: { onChange, value },
    meta: { touched, error },
    id,
    ...others
  } = props;

  const showError = showErrorsInline || touched;

  return (
    <DateTimePicker
      id={id}
      keyboard
      clearable
      format="MM-dd-yyyy hh:mm a"
      placeholder="MM-DD-YYYY HH:MM A"
      // eslint-disable-next-line no-shadow
      mask={(value) => (value ? [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M'] : [])}
      error={!!(showError && error)}
      helperText={showError && error}
      value={value || null}
      onChange={onChange}
      fullWidth
      {...others}
    />
  );
};
renderDateTimePicker.propTypes = renderDatePicker.propTypes;
renderDateTimePicker.defaultProps = renderDatePicker.defaultProps;

const TextField = ({ input, label, type, meta: { error, touched }, disabled, required, textarea }) => (
  <div className={touched && error ? 'error' : ''}>
    { !textarea && <input {...input} placeholder={label} type={type} disabled={disabled} required={required} /> }
    { textarea && <textarea {...input} cols="30" rows="10" placeholder={label} disabled={disabled} required={required} /> }
    { touched && error && <span className="error">{error}</span>}
  </div>
);
TextField.propTypes = {
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  meta: PropTypes.shape({ error: PropTypes.string }).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};
TextField.defaultProps = {
  label: undefined,
  disabled: false,
  required: false,
};

export {
  Field,
  reduxForm,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  FormSelect,
  FieldTheme,
  MuiThemeProvider,
  DatePicker,
  createTextMask,
  statesDropdownSelect,
  phoneMask,
  zipCodeMask,
  renderDatePicker,
  renderDateTimePicker,
};
