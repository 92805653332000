import styled from 'styled-components';
import device from '../../device';

const Block = styled.section`
  padding: 2rem 1.5rem;
  position: relative;

  @media ${device.medium} {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  @media ${device.large} {
    padding-left: 10vw;
    padding-right: 10vw;
  }
`;

export default Block;
