/* eslint-disable */
import React from 'react';

export default MenuIcon => (
  <svg viewBox="0 0 1024 1024">
    <title>Menu icon</title>
    <path d="M557.891368,16.1330667 C682.280421,18.7725333 812.921263,19.8229333 934.103579,45.2210667 C951.107368,48.8032 968.084211,52.5469333 985.141895,55.8597333 C985.141895,55.8597333 1000.42105,60.2768 1001.06779,74.4437333 C1002.06484,96.556 981.342316,111.854133 964.311579,123.408533 C947.873684,134.558933 922.112,148.375733 915.994947,128.283467 C797.669053,128.283467 681.930105,98.1450667 565.463579,80.0458667 C501.867789,70.1882667 435.846737,76.5984 373.867789,76.6792 C300.544,76.76 227.786105,87.3448 156.213895,106.1712 C108.382316,118.560533 55.296,114.143467 17.408,81.3656 C4.04210526,69.8112 21.3962105,50.1498667 21.3962105,50.1498667 C24.7646316,46.6754667 30.1541053,46.4330667 35.0585263,45.5712 C90.8395789,35.3096 145.300211,19.4458667 202.752,20.2538667 C302.08,20.9541333 401.408,-16.7525333 498.526316,9.02266667 C517.820632,14.14 537.276632,15.8098667 557.891368,16.1330667 L557.891368,16.1330667 Z"></path>
    <path d="M892.631579,306.447467 C903.329684,307.228533 909.689263,314.258133 920.117895,321.772533 C920.117895,321.772533 926.504421,323.577067 928.256,327.213067 C936.151579,343.750133 921.411368,365.8624 911.548632,388.540267 C907.614316,397.562933 904.003368,410.679467 891.850105,404.0808 C889.505684,402.814933 888.481684,400.6064 887.915789,398.4248 C772.473263,388.701867 657.165474,418.7056 549.160421,430.9872 C452.931368,441.8952 356.001684,447.012533 258.748632,443.188 C203.533474,441.033333 150.150737,424.307733 99.2471579,401.6568 C98.0614737,401.118133 96.8488421,400.6064 95.6631579,400.067733 C84.3722105,394.977333 79.4947368,392.284 84.9111579,383.530667 C98.5465263,365.323733 114.014316,350.160267 136.407579,340.275733 C144.599579,336.666667 165.322105,346.281867 184.400842,351.264533 C288.768,378.6288 400.357053,361.4992 504.077474,342.888267 C546.115368,335.4816 588.476632,333.192267 630.595368,344.100267 C678.426947,355.950933 730.435368,331.737867 778.536421,323.792533 C814.753684,317.759467 850.917053,311.295467 887.349895,306.824533 C887.349895,306.824533 888.077474,306.3128 892.631579,306.447467 L892.631579,306.447467 Z"></path>
    <path d="M729.141895,630.347733 C766.679579,630.509333 801.064421,639.909067 838.170947,649.7936 C859.701895,655.5304 882.310737,655.638133 904.784842,655.5304 C914.970947,655.557333 916.992,655.799733 922.839579,656.365333 C955.499789,659.597333 986.031158,675.541867 1009.52926,698.650667 C1009.52926,698.650667 1015.21516,705.168533 1008.61305,714.5144 C986.866526,745.353067 951.269053,780.016267 921.034105,759.6816 C911.979789,753.6216 910.093474,741.717067 895.622737,737.8656 C873.525895,731.9672 849.785263,732.478933 826.394947,730.809067 C744.394105,724.802933 661.234526,719.12 582.548211,709.5048 L576.107789,708.750667 C569.236211,714.810667 560.64,722.325067 560.64,722.325067 C559.777684,722.944533 559.184842,723.6448 556.193684,724.398933 C439.861895,742.417333 323.530105,760.435733 207.198316,778.4272 L158.72,785.753067 C157.103158,785.564533 157.103158,785.564533 155.567158,785.0528 C105.337263,763.613867 55.1612632,742.094133 4.98526316,720.5744 C4.98526316,720.5744 -5.09305263,712.763733 3.42231579,705.357067 C21.8812632,692.456 40.3941053,679.6088 58.8530526,666.7616 L65.8324211,661.886667 C76.2341053,656.096 91.5402105,672.525333 106.388211,682.571467 L115.496421,688.7392 C244.628211,694.018133 371.792842,662.910133 498.283789,646.857867 C573.116632,637.3504 648.434526,630.913333 723.806316,630.347733 C725.584842,630.347733 727.363368,630.347733 729.141895,630.347733 L729.141895,630.347733 Z"></path>
  </svg>
)
