import { createElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTreatment } from '../reducer';

function featureFlagHOC(key, { enabled, alt, defaultValue, componentLookup }) {
  const mapStateToProps = (state) => ({
    featureValue: getTreatment(state, key, defaultValue),
  });
  const FeatureFlagComponent = ({ featureValue, ...rest }) => {
    let component = null;
    if (componentLookup) {
      component = componentLookup[featureValue];
    }
    if (!component) {
      component = featureValue ? enabled : alt;
    }
    if (component) {
      return createElement(component, rest);
    }
    return null;
  };
  FeatureFlagComponent.propTypes = {
    featureValue: PropTypes.string,
  };
  FeatureFlagComponent.defaultProps = {
    featureValue: undefined,
  };
  return connect(mapStateToProps)(FeatureFlagComponent);
}

export default function featureFlag(key, ...args) {
  let options = args.pop();
  if (typeof options !== 'object' || (!options.componentLookup && !options.enabled)) {
    args.push(options);
    options = {};
  }
  options.defaultValue = options.defaultValue || '';
  if (args.length > 0) {
    options.enabled = args.shift();
    if (args.length > 0) {
      options.alt = args.shift();
      if (args.length > 0) {
        options.defaultValue = args.shift();
      }
    }
  }
  return featureFlagHOC(key, options);
}
