import styled from 'styled-components';
import theme from '../../../common/theme';
import device from '../../../common/device';

const ProductStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .product-header {
    flex-shrink: 1;
  }

  .product-match {
    background-color: ${theme.color.tertiary.default};
    padding: 0.5rem;
    text-align: center;
  }

  .product-body {
    background-color: ${theme.color.secondary.lighter};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 2;
    padding: 1rem;

    @media ${device.medium} {
      padding: 1.5vw;
    }
  }

  .product-meta {
    margin-top: 0.5vw;
    margin-bottom: 1rem;
  }

  .product-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 0;
    padding-bottom: 80%;
    width: 100%;
  }

  .product-cta {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    @media ${device.large} {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .product-factor-grid {
    .product-factor {
      background-color: ${theme.color.secondary.light};
      margin: 0 0.5rem;
      width: 25%;
      height: 25%;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:after {
        display: none;
      }

      &[data-active='true'] {
        &.factor-body {
          background-color: ${theme.color.factor.body};
        }
        &.factor-mind {
          background-color: ${theme.color.factor.mind};
        }
        &.factor-routine {
          background-color: ${theme.color.factor.routine};
        }
        &.factor-social {
          background-color: ${theme.color.factor.social};
        }

        svg path {
          fill: ${theme.color.neutral.lighter};
        }
      }

      svg path {
        fill: ${theme.color.secondary.default};
      }
    }
  }
`;

export default ProductStyles;
