import styled from 'styled-components';
import AssessmentResultStyles from '../assessment_result/assessment_result_styles';
import ProductStyles from '../product/product_styles';
import theme from '../../../common/theme';
import device from '../../../common/device';

const ReportsStyles = styled(AssessmentResultStyles)`
  background-color: ${theme.color.white};

  .dashboard-container {
    width: calc(100vw - 250px);
  }

  .sleep-factors {
    flex-direction: column;

    .sleep-factor {
      width: 100%;
    }
  }

  .wrapper {
    padding: 0 1rem;
  }

  .wrapper-intro {
    background-image: url('/images/bg-dashboard-results.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;

    @media ${device.medium} {
      height: 22.5vw;
    }

    .row {
      position: relative;
      z-index: 1;
    }

    [class^='col-md-'] {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  a {
    border-bottom: 1px solid ${theme.color.secondary.default};
    color: ${theme.color.text.dark};
    text-decoration: none;
  }

  .result-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    width: 100%;
  }

  .result-factors {
    display: flex;
    flex-direction: row;
  }

  .factor {
    background-color: ${theme.color.neutral.lighter};
    display: flex;
    flex-direction: row;
    width: calc(25% - (3rem/4));
    margin: 0 0.5rem 2rem;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    header {
      width: 50%;
      padding: 0.5rem;

      &[data-factor='mind'] {
        background-color: ${theme.color.factor.mind};
      }

      &[data-factor='body'] {
        background-color: ${theme.color.factor.body};
      }

      &[data-factor='social'] {
        background-color: ${theme.color.factor.social};
      }

      &[data-factor='routine'] {
        background-color: ${theme.color.factor.routine};
      }
    }
  }

  .factor-graph {
    padding: 0.5rem;
  }

  .product-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  ${ProductStyles} {
    margin: 0 0.5rem 2rem;
    width: calc(33% - (2rem / 3));

    &:nth-of-type(3n - 2) {
      margin-left: 0;
    }

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
`;

export default ReportsStyles;
