import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../../common/components/logo';
import SidebarStyles from './sidebar_styles';
import NavDrawer from '../nav_drawer';
import MenuIcon from '../../../common/components/icon/menu_icon';

const propTypes = {
  hideDrawer: PropTypes.func,
  toggleDrawer: PropTypes.func,
  responses: PropTypes.shape([]).isRequired,
  response: PropTypes.shape({}).isRequired,
};

const defaultProps = {
  hideDrawer: () => {},
  toggleDrawer: () => {},
};

const SidebarView = ({ response, responses, hideDrawer, toggleDrawer }) => (
  <SidebarStyles>

    <div className="logo">
      <Logo />

      <div role="presentation" className="menu-toggle" onClick={() => toggleDrawer()}>
        <MenuIcon />
      </div>
    </div>

    <NavDrawer>
      <nav className="sidebar-nav">
        <NavLink exact to="/dashboard" onClick={() => hideDrawer()}>
          Dashboard
        </NavLink>
        {response && (
          <NavLink exact to={`/dashboard/reports/${response.id}`} onClick={() => hideDrawer()}>
            Assessment
          </NavLink>
        )}

        <NavLink className={responses && responses.size <= 1 ? 'disabled' : ''} exact to="/dashboard/reports" onClick={() => hideDrawer()}>Past Results</NavLink>
        <NavLink exact to="/dashboard/products" onClick={() => hideDrawer()}>Products</NavLink>

        <hr />

        {/* <NavLink exact to="/dashboard/orders" disabled>My Orders</NavLink> */}
        <a href="/" className="disabled" onClick={() => hideDrawer()}>My Orders</a>
      </nav>

      <nav className="sidebar-nav sidebar-footer">
        <NavLink exact to="/dashboard/profile" onClick={() => hideDrawer()}>
          Profile
        </NavLink>
        <Link to="/signout" key="/signout" onClick={() => hideDrawer()}>
          Sign Out
        </Link>
      </nav>
    </NavDrawer>
  </SidebarStyles>
);

SidebarView.propTypes = propTypes;
SidebarView.defaultProps = defaultProps;

export default SidebarView;
