/* eslint-disable */
import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  email: undefined,
  username: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  daytimeFunctionScore: 0.0,
  nighttimeSleepScore: 0.0,
  mindScore: 0.0,
  bodyScore: 0.0,
  socialScore: 0.0,
  routineScore: 0.0,
  environmentScore: 0.0,
  anxietyScore: 0.0,
  stressScore: 0.0,
  negativeMoodScore: 0.0,
  overactiveMindScore: 0.0,
  apneaScore: 0.0,
  restlessLegScore: 0.0,
  painScore: 0.0,
  physicalDiscomfortScore: 0.0,
  menopauseScore: 0.0,
  pregnantScore: 0.0,
  childrenScore: 0.0,
  workScore: 0.0,
  bedPartnerScore: 0.0,
  petScore: 0.0,
  bedtimeScore: 0.0,
  exerciseScore: 0.0,
  relaxationScore: 0.0,
  desrieForChangeScore: 0.0,
  disruptsEnvironmentScore: 0.0,
  consistancyScore: 0.0,
  weightedMindScore: 0.0,
  weightedMindScoreFactor: undefined,
  weightedBodyScore: 0.0,
  weightedBodyScoreFactor: undefined,
  weightedSocialScore: 0.0,
  weightedSocialScoreFactor: undefined,
  weightedRoutineScore: 0.0,
  weightedRoutineScoreFactor: undefined,
  weightedEnvironmentScore: 0.0,
  weightedEnvironmentScoreFactor: undefined,
};

class ResponseModel extends Record(DEFAULT_ARGS) {
  static _name = 'Somn::Response';

  score(attr) {
    return Math.floor(this.get(attr) * Math.floor(100));
  }

  get name() {
    return this.username;
  }
}

export default ResponseModel;
