// eslint-disable-next-line filenames/match-exported

// Widths
export const width = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopM: '1280px',
  laptopL: '1440px',
  desktop: '2560px',

  small: '375px',
  medium: '768px',
  large: '1024px',
  xlarge: '1280px',
};

// Variables for use in '@media #{ $device-size }'
const device = {
  small: `(min-width: ${width.small})`,
  medium: `(min-width: ${width.medium})`,
  large: `(min-width: ${width.large})`,
  xlarge: `(min-width: ${width.xlarge})`,

  onlySmall: '(max-width: 767px)',

  // mobileS: `(min-width: ${width.mobileS})`,
  // mobileM: `(min-width: ${width.mobileM})`,
  // mobileL: `(min-width: ${width.mobileL})`,
  // tablet: `(min-width: ${width.tablet})`,
  // laptop: `(min-width: ${width.laptop})`,
  // laptopM: `(min-width: ${width.laptopM})`,
  // laptopL: `(min-width: ${width.laptopL})`,
  // desktop: `(min-width: ${width.desktop})`,
  //
  // onlyMobileS: `(min-width: ${width.mobileS}) and (max-width: ${width.mobileM})`,
  // onlyMobileM: `(min-width: ${width.mobileM}) and (max-width: ${width.mobileL})`,
  // onlyMobileL: `(min-width: ${width.mobileL}) and (max-width: ${width.tablet})`,
  // onlyTablet: `(min-width: ${width.tablet}) and (max-width: ${width.laptop})`,
  // onlyLaptop: `(min-width: ${width.laptop}) and (max-width: ${width.laptopM})`,
  // onlyLaptopM: `(min-width: ${width.laptopM}) and (max-width: ${width.laptopL})`,
  // onlyLaptopL: `(min-width: ${width.laptopL}) and (max-width: ${width.desktop})`,
};

export default device;
