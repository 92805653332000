import styled from 'styled-components';
import AssessmentResultStyles from '../assessment_result/assessment_result_styles';
import ProductStyles from '../product/product_styles';
import theme from '../../../common/theme';
import device from '../../../common/device';

const DashboardProductsStyles = styled(AssessmentResultStyles)`
  background-color: ${theme.color.white};

  .dashboard-container {
    width: calc(100vw - 250px);
  }

  .sleep-factors {
    flex-direction: column;

    .sleep-factor {
      width: 100%;
    }
  }

  .wrapper-intro {
    .row {
      position: relative;
      z-index: 1;
    }

    [class^='col-md-'] {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  .product-grid {
    padding: 0;

    @media ${device.medium} {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
    }
  }

  ${ProductStyles},
  .product-item {
    margin-bottom: 2rem;
    width: 100%;

    @media ${device.medium} {
      margin: 0 0.5rem 2rem;
      width: calc(33% - (2rem / 3));
    }

    &:nth-of-type(3n - 2) {
      margin-left: 0;
    }

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
`;

export default DashboardProductsStyles;
