import styled from 'styled-components';
import theme from '../../theme';
import device from '../../device';

const TextSubheading = styled.div`
  font-family: ${theme.font.serif.light};
  font-size: 1.3rem;
  font-weight: normal;
  color: inherit;
  margin-top: 0;

  ${({ color }) => {
    switch (color) {
      case 'dark':
        return `
          color: ${theme.color.text.dark}
        `;
      case 'light':
        return `
          color: ${theme.color.text.light}
        `;
      default:
      case color:
        return `
          color: ${color}
        `;
    }
  }};

  @media ${device.small} {
    font-size: 1.3rem;
  }

  @media ${device.medium} {
    font-size: 1.8vw;
  }

  @media ${device.large} {
    font-size: 2vw;
  }

  @media ${device.xlarge} {
    font-size: 2vw;
  }
`;

export default TextSubheading;
