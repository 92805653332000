import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import ErrorBoundary from '../../util/error_boundary';
import RootStyles from './root_styles';
import MockAuth from '../../session/components/mock_auth';

// Import View components for Routes
import SignIn from '../../session/components/sign_in';
import FinishSignIn from '../../session/components/finish_sign_in';
import Dashboard from './dashboard';
import AssessmentResult from './assessment_result';
import Profile from './profile';
import SignOut from '../../session/components/sign_out';
import Register from '../../session/components/register';
import ForgotPassword from '../../session/components/forgot_password';
import ResetPassword from '../../session/components/reset_password';
import AuthenticatedRoute from '../../util/authenticated_route';
import ConfirmEmail from '../../session/components/confirm_email';
import { getWordpressUrl } from '../wp';

let mockAuthRoute = null;
if (process.env.NODE_ENV !== 'production') {
  mockAuthRoute = <Route path="/mockauth/:provider/:returnTo" component={MockAuth} />;
}

const RootRedirect = () => {
  if (navigator.userAgent !== 'ReactSnap') {
    window.location = getWordpressUrl('/');
  }
  return null;
};

const RootView = () => (
  <RootStyles>
    <ErrorBoundary>
      <Switch>
        <Route exact path="/" component={RootRedirect} />

        <Route path="/assessment-results/:id" component={AssessmentResult} />

        <Route path="/signin" component={SignIn} />
        <Route path="/finish_signin" component={FinishSignIn} />
        <Route path="/signup" component={Register} />
        <Route path="/register" component={Register} />
        <Route path="/signout" component={SignOut} />
        <AuthenticatedRoute path="/dashboard" component={Dashboard} />
        <AuthenticatedRoute path="/profile" component={Profile} />
        <Route path="/forgot" component={ForgotPassword} />
        <Route path="/reset_password/:token" component={ResetPassword} />
        <AuthenticatedRoute path="/confirm_email/:token" component={ConfirmEmail} />
        {mockAuthRoute}

        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </ErrorBoundary>
  </RootStyles>
);

export default RootView;
